import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";
import { useStaticQuery, graphql, Link } from "gatsby";
import ResourceBanner from "../../components/ResourceBanner";
import { GatsbyImage } from "gatsby-plugin-image";
import ArrowIcon from "../../components/ArrowIcon";
import ogImage from "../../images/og.png";
const EventsPage = ({ location }) => {
    const eventDetails = useStaticQuery(graphql`
        {
            contentfulEvents(title: { eq: "FABTECH Orlando" }) {
                title
                description
                dateTime
                upcomingEvent
                featured
                eventType {
                    title
                }
                date
                externalUrl
                eventBanner {
                    gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                }
            }
            allContentfulEvents {
                nodes {
                    title
                    description
                    dateTime
                    upcomingEvent
                    featured
                    eventType {
                        title
                    }
                    readTime
                    externalUrl
                    date
                    eventBanner {
                        gatsbyImageData(quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
                        title
                    }
                }
            }
            allContentfulEventsCategory {
                nodes {
                    title
                }
            }
        }
    `);
    const upcomingEvents = eventDetails.allContentfulEvents.nodes.filter(
        (event) => event.upcomingEvent
    );
    const pastEvents = eventDetails.allContentfulEvents.nodes.filter(
        (event) => !event.upcomingEvent
    );
    return (
        <Layout>
            <Seo
                title={" Events - Sedin"}
                description={
                    "Get to know details about all the exciting events, technology conferences, business gatherings and more our team will be heading to. Explore our schedule."
                }
                url={location.href}
                image={`https://sedintechnologies.com${ogImage}`}
            />
            <section className="events-banner">
                <div className="container container--first-v3 ">
                    <div className="events-banner__text">
                        <div className="section-tag gap-2x">Events</div>
                        <h1 className="text-h1  text-clr-primary text-fw-medium ">
                            Connect. Collaborate. <br></br> Catch up with Sedin across the globe
                        </h1>
                    </div>
                    <ResourceBanner
                        image={eventDetails.contentfulEvents.eventBanner.gatsbyImageData}
                        title={eventDetails.contentfulEvents.title}
                        slug={eventDetails.contentfulEvents.externalUrl}
                        description={eventDetails.contentfulEvents.description}
                        eventType={eventDetails.contentfulEvents.eventType.title}
                        date={eventDetails.contentfulEvents.dateTime}
                        events
                    />
                </div>
            </section>
            <section>
                <div className="container container--mid-v2">
                    <div className="upcoming-events">
                        <h2 className="text-h2 upcoming-events__text text-clr-primary text-fw-medium ">
                            Upcoming events
                        </h2>
                        <div className="upcoming-events__grid">
                            {upcomingEvents.map((el, i) => (
                                <div className="upcoming-events__grid-item" key={i}>
                                    <div className="upcoming-events__grid-item__image">
                                        <GatsbyImage
                                            image={el.eventBanner.gatsbyImageData}
                                            alt={el.title}
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="upcoming-events__grid-item__info">
                                        <div className="upcoming-events__grid-item__info-time">
                                            <p className="text-decor-none text-clr-primary">
                                                {el?.eventType?.title}
                                            </p>
                                            <div className="text-p4  text-fw-regular">
                                                {el.dateTime}
                                            </div>
                                        </div>
                                        <div className="text-p1 gap-05x text-fw-medium">
                                            {el.title}
                                        </div>
                                        <div className="text-p4 text-clr-secondary upcoming-events__grid-item__info-description gap-3x text-fw-regular">
                                            {el.description}
                                        </div>
                                        {el.externalUrl && (
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href={el.externalUrl}
                                                className="sdn-link"
                                            >
                                                See event details
                                            </a>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <section className="past-events">
                <div className="container container--last-v2">
                    <div className="past-events__header">
                        <h2 className="text-h2 text-clr-primary text-fw-medium">Past events</h2>
                    </div>
                    <div className="past-events__grid">
                        {pastEvents.map((el, i) => (
                            <Link
                                to={el.externalUrl}
                                key={i}
                                className="past-events__grid-item text-decor-none text-clr-primary"
                            >
                                <div className="past-events__grid-item__image">
                                    <GatsbyImage
                                        image={el.eventBanner.gatsbyImageData}
                                        alt={el.title}
                                        loading="lazy"
                                    />
                                </div>
                                <div className="past-events__grid-item__content">
                                    <div>
                                        <h3 className="text-p3 past-events__grid-item__content-title text-fw-medium text-clr-primary">
                                            {el.title}
                                        </h3>
                                        <div className="past-events__grid-item__content-info">
                                            {/* <p>{el.services.title}</p> */}
                                            <p className="text-p6 text-clr-primary text-fw-regular">
                                                {el.date}
                                            </p>
                                            <p className="text-p6 text-clr-primary text-fw-regular">
                                                {el.readTime}
                                            </p>
                                        </div>
                                        <div className="mobile-arrow arrow">
                                            <ArrowIcon direction="right" theme={"blue"} />
                                        </div>
                                    </div>
                                    <div className="desktop-arrow arrow">
                                        <ArrowIcon direction="right" theme={"blue"} />
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default EventsPage;
